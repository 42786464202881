import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import {
  Grid, createStyles, makeStyles, Container
} from '@material-ui/core'
import Master from '../components/Master'

const Page = () => {

  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      center: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      },
      width: {
        width: '100%'
      },
      imagePaper: {
        width: '100%',
        marginTop: '10px',
        borderRadius: '4px'
      },
    }),
  )

  const classes = useStyles()

  return (
    <Master title={`Página não encontrada`}>
      <GatsbySeo
        title={`Página não encontrada`}
        description={`Página não encontrada`}
      />
      <Container maxWidth="md" component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>{`Página não encontrada`}</h1>
            <p>Esta página não foi encontrada.</p>
          </Grid>
        </Grid>
      </Container>
    </Master>
  )
}

export default Page